/* eslint-disable react/react-in-jsx-scope */
import { motion, useAnimation } from 'framer-motion';
import { PageProps } from 'gatsby';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import tw, { css } from 'twin.macro';
import { FSOverlay, Highlight, Layout } from '~/components/common';
import { runPageExitSequence } from '~/components/common/transitions/page-exit';
import { HomeContent } from '~/components/home/home-content';
import { SEO } from '~/components/SEO';
import { Frontmatter } from '~/config';
import { useIsMobile } from '~/hooks';

const SLICE_START_DELAY = 5.5;
const SLICE_COUNT = 30;
const SLICE_DURATION = 1.3;
const SLICE_DELAY_MULT = 0.01;
const INERTIA = 0.002; // gently slow the effect down so it doesn't feel rushed or lockstep
const SLICES = new Array(SLICE_COUNT).fill(null);
const SLICE_PERCENT = (2 / SLICE_COUNT) * 100;
const CREDIT_DURATION = 3.14; // how long after the credits are revealed before redirecting

// don't add export - uses local gatsby plugin
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Home',
  order: 0,
  screenShoot: false,
};

const Page: FC<PageProps> = (props) => {
  const [isReady, setIsReady] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const timeoutIntro = useRef<NodeJS.Timeout | null>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();
  const isMobile = useIsMobile();

  useEffect(() => {
    const startSlice = () => {
      controls.start('showing');
      setShowContent(true);
    };

    timeoutIntro.current = setTimeout(
      introComplete,
      (SLICE_START_DELAY + SLICE_DURATION + CREDIT_DURATION) * 1000,
    );
    const timeoutStartSlice = setTimeout(startSlice, SLICE_START_DELAY * 1000);

    return () => {
      timeoutIntro.current && clearTimeout(timeoutIntro.current);
      timeoutStartSlice && clearTimeout(timeoutStartSlice);
    };
  }, [controls, isReady]);

  const introComplete = () => {
    runPageExitSequence('/timeline');
  };

  const enableBypass = () => {
    if (overlayRef.current) {
      overlayRef.current.style.display = 'none';
    }
  };

  const hurryUp = () => {
    setShowContent(true);
    controls.start('showing');
    if (overlayRef.current) {
      overlayRef.current.style.opacity = '0';
    }
    enableBypass();

    if (timeoutIntro.current) {
      clearTimeout(timeoutIntro.current);
      timeoutIntro.current = setTimeout(introComplete, CREDIT_DURATION * 1000);
    }
  };

  const strips = useMemo(
    () =>
      SLICES.map((_, index) => {
        const top = `${(SLICE_PERCENT * Math.floor(index / 2)).toFixed(3)}%`;
        const height = `${Math.ceil(SLICE_PERCENT) + 1}%`; // overlap a tad

        const duration = SLICE_DURATION + index * INERTIA * Math.random();
        const delay = SLICE_DELAY_MULT * duration * (Math.random() * 25.0);

        return (
          <motion.div
            key={`strip-${index}`}
            tw="[z-index: 49]"
            css={[
              css`
                background-color: black;
                position: fixed;
              `,
            ]}
            variants={{
              hidden: {
                top: top,
                right: index % 2 ? '0%' : '50%',
                left: index % 2 ? '50%' : '0%',
                height,
              },
              showing: {
                left: index % 2 ? '100%' : '0%',
                right: index % 2 ? '0%' : '100%',
              },
            }}
            initial="hidden"
            animate={controls}
            transition={{
              delay,
              duration,
              ease: 'easeOut',
            }}
          />
        );
      }),
    [controls],
  );

  return (
    <Layout noHeader noSideNav>
      <SEO path="/" />
      <Container>
        <FSOverlay customCss={tw`bg-white`} scrollable={isMobile}>
          <HomeContent
            isReady={isReady}
            setIsReady={setIsReady}
            isShowing={showContent}
          />
        </FSOverlay>

        <FSOverlay
          ref={overlayRef}
          center
          tw="fixed [z-index:50] inset-0 text-center transition-opacity [transition-duration:1s]"
        >
          {/* TODO: a11y issues ahead! needs kbd and role handler */}
          {/* eslint-disable-next-line  */}
          <div onClick={hurryUp} tw="cursor-pointer">
            <motion.h1
              tw="opacity-0 text-brand-gray-light inline-block w-auto font-bold text-4xl mr-1"
              animate={{ opacity: [0, 1, 1, 0] }}
              transition={{ delay: 0, duration: 6.5, times: [0, 0.08, 0.8, 1] }}
            >
              Dicta
            </motion.h1>
            <motion.h2
              tw="opacity-0 text-brand-gray-light inline-block w-auto text-base ml-1"
              animate={{ opacity: [0, 1, 1, 0] }}
              transition={{ delay: 1, duration: 5.5, times: [0, 0.08, 0.8, 1] }}
            >
              legal poetry by <strong>harbani ahuja</strong>
            </motion.h2>
            <motion.h3
              tw="opacity-0 text-brand-gray-light mt-4 text-xl"
              animate={{ opacity: [0, 1, 1, 0] }}
              transition={{ delay: 3, duration: 4, times: [0, 0.08, 0.5, 1] }}
              onAnimationComplete={enableBypass}
            >
              Poetry of the legal cases that have
              <Highlight
                color="black"
                bgColor="white"
                delay={3.5}
                customCss={tw`inline-block font-medium ml-1 py-1 pl-3 pr-9 mt-1 lg:(mt-0)`}
              >
                shaped our nation
              </Highlight>
            </motion.h3>
          </div>
        </FSOverlay>
        {strips}
      </Container>
    </Layout>
  );
};

const Container = tw.div`relative w-screen h-screen overflow-hidden`;

export default Page;
